import React, { useState } from 'react';
import ImageModal from './ImageModal';

const AppScreenshotList = ({ screenshots }) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="screenshot-list-container">
      <div className="screenshot-list">
        {screenshots.map((screenshot, index) => (
          <img
            key={index}
            src={screenshot}
            alt={`Screenshot ${index + 1}`}
            className="screenshot-image"
            onClick={() => openFullscreen(screenshot)}
          />
        ))}
      </div>
      {fullscreenImage && (
        <ImageModal imageUrl={fullscreenImage} onClose={closeFullscreen} />
      )}
    </div>
  );
};

export default AppScreenshotList;