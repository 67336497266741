import React from 'react';
import './whatspp.css';

// import {ReactComponent as Image1} from '../../assets/images/image1.svg';
import {ReactComponent as Image1} from '../../assets//icons/img1.svg';
import {ReactComponent as Image2} from '../../assets//icons/img2.svg';
import {ReactComponent as Image3} from '../../assets//icons/img3.svg';

function whatapp() {
  return (
    <div className='whatapp'>
        <h2>ماذا تقدم المنصة؟</h2>
        <div className='divider'></div>
        <div className='whatapp-content'>
            <div className='whatapp-content_contaoner'>
                <Image1 className="img"/>
                <h3>منافسة بين طلاب الدورات</h3>
                <p>نافس زملائك في الدورة التعليمية للحصول على افضل النقاط والجوائز</p>
            </div>
            <div className='whatapp-content_contaoner'>
                <Image2 className="img"/>
                <h3>تواصل مع الأستاذ</h3>
                <p>اسأل وتواصل مع مدرسي المادة والدورة الخاصة بك</p>
            </div>
            <div className='whatapp-content_contaoner'>
                <Image3 className="img"/>
                <h3>شرح لكل المواد</h3>
                <p>دراسة كل المواد الدراسية وحل التمارين والاسئلة الوزارية والواجبات</p>
            </div>
        </div>
    </div>
  )
}

export default whatapp