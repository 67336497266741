import React, { useState } from 'react';
import './alaa_app.css'

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content">
        
        <img src={imageUrl} alt="Fullscreen" className="fullscreen-image" />
      </div>
    </div>
  );
};

export default ImageModal;
