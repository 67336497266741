import { React, useState } from "react";
import "./navbar.css";
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";
import AppLogo from '../../assets/icons/appMainLogo.png';

import { Link as rLink } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={isOpen ? "navbar menu" : "navbar"}>
      <div className={isOpen ? "no" : "navbar-nav"}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a href="/downloud">
              <rLink to="downloud" >
                تحميل التطبيق
              </rLink>
            </a>
          </li>
          <li className="nav-item">
            <a href="#teacher">
              <Link to="teacher" smooth={true} duration={1000}>
                المدرس
              </Link>
            </a>
          </li>
          <li className="nav-item">
            <a href="#teacher">
              <Link to="mnahg" smooth={true} duration={1000}>
                المناهج
              </Link>
            </a>
          </li>
          <li className="nav-item">
            <a href="">
              <Link to="student" smooth={true} duration={1000}>
                الطالب
              </Link>
            </a>
          </li>
          <li className="nav-item">
            <a href="">
              <Link to="home" smooth={true} duration={1000}>
                الرئيسية
              </Link>
            </a>
          </li>
        </ul>
      </div>
      <div className="hamburger">
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      <div
        className="menu-content"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        <ul className="menu-content_navbar">
        <li className="nav-item">
            <a href="">
              <Link
                to="home"
                smooth={true}
                duration={1000}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                الرئيسية
              </Link>
            </a>
          </li>
        <li className="nav-item">
            <a href="">
              <Link
                to="student"
                smooth={true}
                duration={1000}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                الطالب
              </Link>
            </a>
          </li>
        <li className="nav-item">
            <a href="">
              <Link
                to="mnahg"
                smooth={true}
                duration={1000}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                المناهج
              </Link>
            </a>
          </li>
          <li className="nav-item">
            <a href="">
              <Link
                to="teacher"
                smooth={true}
                duration={1000}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                المدرس
              </Link>
            </a>
          </li>
          <li className="nav-item">
            <a href="/downloud">
              <rLink
                to="downloud"
                smooth={true}
                duration={1000}
                onClick={() => {
                  setOpen(!isOpen);
                }}
              >
                تحميل التطبيق
              </rLink>
            </a>
          </li>
        </ul>
      </div>
      <div className="logoDiv" style={{ display: isOpen ? "none" : "flex" }}>
        <h1 >الريحاني اكاديمي</h1>
      <img className="logoImg" src={AppLogo} />
      </div>
      
    </div>
  );
};

export default Navbar;
