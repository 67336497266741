import React from 'react';
import './teacher.css';
import {ReactComponent as Teacher} from '../../assets/images/teacher.svg';
import {ReactComponent as Chat} from '../../assets/images/chat2.svg';
import {ReactComponent as Clock} from '../../assets/images/clock.svg';
import {ReactComponent as Check} from '../../assets/images/check.svg';
import GooglePlay from '../../assets/icons/googleplay.png';
import Apple from '../../assets/icons/apple.png';
import AppGallery from '../../assets/icons/appgallery.png';


function tracher() {
  return (
    <div className='teacher'>
      <div className='title'>
      <h2>المدرس</h2>
        <div className='divider'></div>
      </div>
        
        <div className='teacher-content'>
          <Teacher className='imgS'/>
          <div className='teacher-content_texts'>
            <h2>يستطيع المدرس ادارة ومتابعة طلاب المجموعة الخاصة به على المنصة التعليمية</h2>
            <div className='section'>
              <div className='section-title'>
                <h3>التواصل مع الطلاب</h3>
                <p>التواصل مع طلاب المجموعة من خلال محادثة او البث المباشر</p>
              </div>
              <Chat className='img'/>
            </div>
            <div className='section'>
              <div className='section-title'>
                <h3>اضافة اختبارات وتنبيهات</h3>
                <p>اضافة الاختبارات والامتحانات والاشعارات والتنبيهات</p>
              </div>
              <Clock className='img'/>
            </div>
            <div className='section'>
              <div className='section-title'>
                <h3>ارسال المهام و الواجبات</h3>
                <p>ارسال وتقييم المهام و الواجبات الدراسية من المنصة</p>
              </div>
              <Check className='img'/>
            </div>
            
            <h4>حمل تطبيق المدرس</h4>
            <div className='getiton'>
            <a href='#teacher'>
                <img src={AppGallery}/>
              </a>
              <a href='#teacher'>
                <img src={Apple}/>
              </a>
              
              <a href='#teacher'>
                <img src={GooglePlay}/>
              </a>
            </div>
          </div>
        </div>
    </div>
  )
}

export default tracher