import React from "react";
import "./footer.css";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Twiter } from "../../assets/icons/twt.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as GooglePlay } from "../../assets/icons/googleplay_white.svg";
import { ReactComponent as Apple } from "../../assets/icons/apple_white.svg";
import { ReactComponent as AppGallry } from "../../assets/icons/appgallery_white.svg";

function footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-container_title">
          <h1>الريحاني اكاديمي</h1>
        </div>
        <div className="footer-container_contact">
          <h3>تابعنا على مواقع التواصل الاجتماعي</h3>
          <div className="footer-container_contact-icons">
            <Facebook className="img" />
            <Twiter className="img" />
            <Instagram className="img" />
            <Youtube className="img" />
          </div>
          <p>
          حقوق الطبع والنشر ©2023 . جميع الحقوق محفوظة لمنصة الريحاني اكاديمي
        </p>
        </div>
        <div className="footer-container_downloud">
          <GooglePlay className="img" />
          <Apple className="img" />
          <AppGallry className="img" />
        </div>
        
      </div>

      <div className="mobile-footer-container">
        <div className="mobile-footer-container_title">
          <h1>الريحاني اكاديمي</h1>
        </div>
        <div className="mobile-footer-container_contact">
          <h3>تابعنا على مواقع التواصل الاجتماعي</h3>
          <div className="mobile-footer-container_contact-icons">
            <Facebook className="img" />
            <Twiter className="img" />
            <Instagram className="img" />
            <Youtube className="img" />
          </div>
          <div className="mobile-footer-container_downloud">
          <GooglePlay className="img" />
          <Apple className="img" />
          <AppGallry className="img" />
        </div>
          <p>
          حقوق الطبع والنشر ©2023 . جميع الحقوق محفوظة لمنصة الريحاني اكاديمي
        </p>
        </div>
        
        
      </div>
    </div>
  );
}

export default footer;
