
import { React, useState } from 'react'
import "./alaa_app.css";
import appLogo from '../../assets/downloud/alaa_logo.png';

import AppScreenshotList from './AppScreenshotList';

import ap1 from '../../assets/downloud/alaa_1.png';
import ap2 from '../../assets/downloud/alaa_2.png';
import ap3 from '../../assets/downloud/alaa_3.png';
import ap4 from '../../assets/downloud/alaa_4.png';
import ap5 from '../../assets/downloud/alaa_5.png';
import ap6 from '../../assets/downloud/alaa_6.png';

import GooglePlay from "../../assets/downloud/googleplay_ar.png";
import Apple from "../../assets/downloud/appstore_ar.png";
import apk from "../../assets/downloud/apk_ar.png";



function Alaa_app() {
  const screenshots = [
    ap1,
    ap2,
    ap3,
    ap4,
    ap5,
    ap6,
    // Add more screenshot URLs as needed
  ];

  const [isContainerVisible, setIsContainerVisible] = useState(false);

  const toggleContainer = () => {
    setIsContainerVisible(!isContainerVisible);
  };

  const containerStyles = {

    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.800)',
    display: isContainerVisible ? 'flex' : 'none',
    zIndex: 9999,

  };

  return (
    <div className='downloudPage_d'>
      <dev className="title_d">
        <h1>حمل الان تطبيق منصة <span>الاستاذ علاء السعداوي</span></h1>
      </dev>
      <dev className="downloudInfo_d">
        <button className='apk' onClick={toggleContainer}>تحميل التطبيق</button>
        <div className='d-container' style={containerStyles} onClick={toggleContainer}>
          <div className="i-container">
            <a href='https://play.google.com/store/apps/details?id=com.edu.alaa_al_saadawi' rel="noopener noreferrer" target="_blank">
              <img src={GooglePlay}></img>
            </a>
            <a href='https://apps.apple.com/us/app/%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%A7%D8%B0-%D8%B9%D9%84%D8%A7%D8%A1-%D8%A7%D9%84%D8%B3%D8%B9%D8%AF%D8%A7%D9%88%D9%8A/id6473701235' el="noopener noreferrer" target="_blank">
              <img src={Apple}></img>
            </a>
            <a href='https://al-rihani.academy/app/alaa_alsaadawi.apk'>
              <img src={apk}></img>
            </a>
            
          </div>
        </div>
        <div className='right_d'>
          <dev className="appInfo_d">
            <h1>الاستاذ علاء السعداوي</h1>
            <h2>AL - Rihani co.</h2>
            <h3>version 1.0.0</h3>
          </dev>
          <img className='appLogo_d' src={appLogo}></img>
        </div>
      </dev>
      <AppScreenshotList screenshots={screenshots} />
    </div>
  )
}

export default Alaa_app