import React from "react";
import "./mnahg.css";
import { ReactComponent as AppAbout } from '../../assets/images/appAbout.svg';
import { Link } from "react-router-dom";

function mnahg() {
  return (
    <div className="mnahg">
      <div className="title">
        <h2 className="titleText">المناهج التعليمية</h2>
        <div className="divider"></div>
      </div>
      <div className="mnahg-content">
        <AppAbout className="img" />
        <div className="mnahg-content_texts">
          <h4>تقدم المنصة</h4>
          <h3>شروحات ودروس تعليمية لجميع المراحل الدراسية التربوية</h3>
          <p>شرح المناهج بشكل متكامل من الاول متوسط الى الصف السادس الاعدادي بطريقة مبسطة وسهله في كل المواد من خلال شروحات بفيدوهات تفاعلية مع افضل الاساتذة والمدرسين</p>
          <Link to="downloud" className="dLink">
            <div className="dButton">
              <h2>حمل تطبيق المنصة</h2>
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
}

export default mnahg;
