
import { React, useState } from 'react'
import './downloud_screen.css'
import appLogo from '../../assets/downloud/google_icon.png';

import AppScreenshotList from './AppScreenshotList';

import ap1 from '../../assets/downloud/ap1.png';
import ap2 from '../../assets/downloud/ap2.png';
import ap3 from '../../assets/downloud/ap3.png';
import ap4 from '../../assets/downloud/ap4.gif';

import GooglePlay from "../../assets/downloud/googleplay_ar.png";
import Apple from "../../assets/downloud/appstore_ar.png";
import apk from "../../assets/downloud/apk_ar.png";



function Downloud_screen() {
  const screenshots = [
    ap1,
    ap2,
    ap3,
    ap4,
    // Add more screenshot URLs as needed
  ];

  const [isContainerVisible, setIsContainerVisible] = useState(false);

  const toggleContainer = () => {
    setIsContainerVisible(!isContainerVisible);
  };

  const containerStyles = {

    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.800)',
    display: isContainerVisible ? 'flex' : 'none',
    zIndex: 9999,

  };

  return (
    <div className='downloudPage_d'>
      <dev className="title_d">
        <h1>حمل الان تطبيق منصة <span>الريحاني اكاديمي</span></h1>
      </dev>
      <dev className="downloudInfo_d">
        <button className='apk' onClick={toggleContainer}>تحميل التطبيق</button>
        <div className='d-container' style={containerStyles} onClick={toggleContainer}>
          <div className="i-container">
            <a href='https://play.google.com/store/apps/details?id=com.salardev23.alrihani_academy&hl=ar&gl=US' rel="noopener noreferrer" target="_blank">
              <img src={GooglePlay}></img>
            </a>
            <a href='https://apps.apple.com/iq/app/%D8%A7%D9%84%D8%B1%D9%8A%D8%AD%D8%A7%D9%86%D9%8A-%D8%A7%D9%83%D8%A7%D8%AF%D9%8A%D9%85%D9%8A/id6449501473' el="noopener noreferrer" target="_blank">
              <img src={Apple}></img>
            </a>
            <a href='https://al-rihani.academy/app/al-rihani-academy.apk'>
              <img src={apk}></img>
            </a>
            
          </div>
        </div>
        <div className='right_d'>
          <dev className="appInfo_d">
            <h1>الريحاني اكاديمي</h1>
            <h2>AL - Rihani co.</h2>
            <h3>version 1.3.0</h3>
          </dev>
          <img className='appLogo_d' src={appLogo}></img>
        </div>
      </dev>
      <AppScreenshotList screenshots={screenshots} />
    </div>
  )
}

export default Downloud_screen