import React from 'react';
import './start.css';
import {ReactComponent as Student} from '../../assets/images/student_img.svg';

function start() {
  return (
    <div className='start'>
        <Student className='student_logo'/>
        <div className='about_alrihani'>
            <h2>ذاكر دروسك مع<br/>أفضل الاساتذة على<br/><span>الريحاني اكاديمي</span></h2>
            <p>أسأل وتواصل مع مدرسين المنصة ذاكر و تفاعل مع المحتوى التعليمي عن بعد بشكل سلس</p>
        </div>
    </div>
  )
}

export default start