import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  Navbar,
  Start,
  CountDown,
  WhatApp,
  Student,
  Mnahg,
  Teacher,
  DowmLoud,
  Footer,
  DownloudScreen,
  Alaa_app,
} from "./components/index";
import "./CustomScrollbar.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<div className="App">
          <div className="scrollable-container">
            <Navbar />
            <section id="home">
              <Start />
            </section>
            {/* <CountDown /> */}
            <WhatApp />
            <section id="student">
              <Student />
            </section>
            <section id="mnahg">
              <Mnahg />
            </section>
            <section className="teacher">
              <Teacher />
            </section>
            <section id="downloud">
              <DowmLoud />
            </section>
            <Footer />
          </div>
        </div>}></Route>
        <Route path='/downloud' element={<DownloudScreen/>}></Route>
        <Route path='/alaa_app' element={<Alaa_app/>}></Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;


