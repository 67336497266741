import React from "react";
import "./downloud.css";
import MyApp from "../../assets/images/theapp.png";
import AppMobile from "../../assets/images/myappmobile.png";
import GooglePlay from "../../assets/icons/googleplay.png";
import Apple from "../../assets/icons/apple.png";
import AppGallery from "../../assets/icons/appgallery.png";

function downloud() {
  return (
    <div className="downloud">
      <div className="downloud-container">
        <img className="downloud-container_img" src={MyApp} />
        <div className="downloud-container_texts">
          <h3>حمل الان التطبيق الرسمي</h3>
          <h4>
            يمكنك تحميل التطبيق الرسمي للمنصة والتفاعل مع المحتوى الدراسي
            والاستفادة من الخدمات من خلال التطبيق
          </h4>
          <div className='getiton'>
            <a href='https://appgallery.huawei.com/#/app/C108542939' rel="noopener noreferrer" target="_blank">
              <img src={AppGallery} />
            </a>
            <a href='https://apps.apple.com/iq/app/%D8%A7%D9%84%D8%B1%D9%8A%D8%AD%D8%A7%D9%86%D9%8A-%D8%A7%D9%83%D8%A7%D8%AF%D9%8A%D9%85%D9%8A/id6449501473' el="noopener noreferrer" target="_blank">
              <img src={Apple} />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.salardev23.alrihani_academy&hl=ar&gl=US' rel="noopener noreferrer" target="_blank">
              <img src={GooglePlay} />
            </a>
          </div>
        </div>
      </div>
      <div className="downloud-container-mobile">
        <div className="downloud-container_texts">
          <h3>حمل الان التطبيق الرسمي</h3>
          <h4>
            يمكنك تحميل التطبيق الرسمي للمنصة والتفاعل مع المحتوى الدراسي
            والاستفادة من الخدمات من خلال التطبيق
          </h4>
          <div className='getiton'>
          <a href='https://play.google.com/store/apps/details?id=com.salardev23.alrihani_academy&hl=ar&gl=US' rel="noopener noreferrer" target="_blank">
              <img src={GooglePlay} />
            </a>
            <a href='https://apps.apple.com/iq/app/%D8%A7%D9%84%D8%B1%D9%8A%D8%AD%D8%A7%D9%86%D9%8A-%D8%A7%D9%83%D8%A7%D8%AF%D9%8A%D9%85%D9%8A/id6449501473' el="noopener noreferrer" target="_blank">
              <img src={Apple} />
            </a>
            <a href='https://appgallery.huawei.com/#/app/C108542939' rel="noopener noreferrer" target="_blank">
              <img src={AppGallery} />
            </a>
          </div>
        </div>
        <img className="img" src={AppMobile} />
      </div>
    </div>
  );
}

export default downloud;
