import React from 'react';
import "./student.css";
import { ReactComponent as Student } from '../../assets/images/student_about.svg';
import { ReactComponent as Book } from '../../assets/images/book.svg';
import { ReactComponent as Chat } from '../../assets/images/chat.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as Good } from '../../assets/images/good.svg';
import GooglePlay from '../../assets/icons/googleplay.png';
import Apple from '../../assets/icons/apple.png';
import AppGallery from '../../assets/icons/appgallery.png';

function student() {
  return (
    <div className='student'>
      <div className='title'>
        <h2>الطالب</h2>
        <div className='divider'></div>
      </div>

      <div className='student-content'>
        <Student className='imgS' />
        <div className='student-content_texts'>
          <h2>تقدم <span>الريحاني اكاديمي</span> تجربة تعليمية سهلة للطلاب</h2>
          <div className='section'>
            <div className='section-title'>
              <h3>دراسة المواد الدراسية</h3>
              <p>مشاهدة الدروس التعليمية من خلال فيديوهات تفاعلية</p>
            </div>
            <Book className='img' />
          </div>
          <div className='section'>
            <div className='section-title'>
              <h3>التواصل مع المدرسين</h3>
              <p>سؤال مدرس المادة من خلال المحادثة او البث المباشر</p>
            </div>
            <Chat className='img' />
          </div>
          <div className='section'>
            <div className='section-title'>
              <h3>الواجبات والتمارين</h3>
              <p>حل الاسئلة الخاصة لكل محاضرة والامتحانات ووجود نمط اختبارات شبيه بالامتحانات الوزارية</p>
            </div>
            <Check className='img' />
          </div>
          <div className='section'>
            <div className='section-title'>
              <h3>منافسة زملاء المجموعة</h3>
              <p>نافس زملائك في الدورة التعليمية للحصول على افضل النقاط والجوائز</p>
            </div>
            <Good className='img' />
          </div>
          <h4>حمل تطبيق الطالب</h4>
          <div className='getiton'>
            <a href='https://appgallery.huawei.com/#/app/C108542939' rel="noopener noreferrer" target="_blank">
              <img src={AppGallery} />
            </a>
            <a href='https://apps.apple.com/iq/app/%D8%A7%D9%84%D8%B1%D9%8A%D8%AD%D8%A7%D9%86%D9%8A-%D8%A7%D9%83%D8%A7%D8%AF%D9%8A%D9%85%D9%8A/id6449501473' el="noopener noreferrer" target="_blank">
              <img src={Apple} />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.salardev23.alrihani_academy&hl=ar&gl=US' rel="noopener noreferrer" target="_blank">
              <img src={GooglePlay} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default student